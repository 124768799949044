import React from "react";
import { Outlet, Link } from "react-router-dom";
import { Layout, Menu, Typography } from 'antd';
import {
    BorderlessTableOutlined,
    MessageOutlined,
    ExportOutlined,
    DollarOutlined,
} from '@ant-design/icons';
import withRouter from "./wrappers/withRouter";
import withAntdToken from "./wrappers/withAntdToken";

const { Content, Footer, Sider } = Layout;
const { Title } = Typography;

const menu = [
    {
        href: '/board/balance',
        icon: DollarOutlined,
        label: `Баланс`,
    },
    {
        href: '/board/export',
        icon: ExportOutlined,
        label: `Экспорт`,
    },
    {
        href: '/board/post',
        icon: MessageOutlined,
        label: `Пост`,
    },
    {
        href: '/board/channel',
        icon: BorderlessTableOutlined,
        label: `Канал`,
    },
].map((e, idx) => ({
    key: e.href,
    icon: React.createElement(e.icon),
    label: <Link to={e.href}>{e.label}</Link>,
}));

class Board extends React.Component {
    constructor(props) {
        super(props);
        // should be exported withRouter and withAntdToken
        this.colorBgContainer = this.props.token.colorBgContainer;
        this.borderRadiusLG = this.props.token.borderRadiusLG;
        this.pathname = this.props.location.pathname
        this.state = {
            isSidebarCollapsed: true,
        }
    }
    render() {
        return (
            <Layout hasSider style={{
                minHeight: '100vh',
            }}>
                <Sider
                    collapsible
                    collapsed={ this.state.isSidebarCollapsed }
                    onCollapse={(value) => this.setState({ isSidebarCollapsed: value })}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        position: 'fixed',
                    }}
                >
                    <a href="https://adsee.pro"
                       target="_blank"
                       rel="noreferrer"
                       className="demo-logo-vertical"
                       style={{
                           padding: '20px 0',
                           margin: 8,
                           textAlign: 'center',
                           background: this.colorBgContainer,
                           borderRadius: this.borderRadiusLG,
                           display: "block",
                           cursor: "pointer",
                       }}
                    >
                        <Title
                            level={ this.state.isSidebarCollapsed ? 5 : 4 }
                            style={{
                                margin: 0,
                                userSelect: "none",
                            }}
                        >Ad<span style={{ color: '#ba80e6' }}>See</span></Title>
                    </a>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={this.pathname} items={menu}/>
                </Sider>
                <Layout>
                    <Content
                        style={{
                            margin: 12,
                            overflow: 'initial',
                            marginLeft: this.state.isSidebarCollapsed ? 90 : 210,
                            transition: 'margin 0.2s',
                        }}
                    >
                        <div
                            style={{
                                padding: 24,
                                textAlign: 'center',
                                background: this.colorBgContainer,
                                borderRadius: this.borderRadiusLG,
                                minHeight: '100%',
                            }}
                        >
                            <Outlet/>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center', padding: '6px 50px 16px 50px' }}>
                        <div>AdSee ©{new Date().getFullYear()} Создано W-RED</div>
                        <div>сборка 1.25.4 beta</div>
                    </Footer>
                </Layout>
            </Layout>
        );
    }
};

export default withAntdToken(withRouter(Board))
// withAntdToken(
    // withRouter(

    // )
// )();

import React from "react";
import {withTranslation} from "react-i18next";
import {Badge, Button, Col, Empty, Input, Popover, Result, Row, Space, Spin, Switch, Table, Tooltip, Tree} from "antd";
import {CloseCircleOutlined, QuestionCircleOutlined, SettingOutlined} from "@ant-design/icons";
import channelService from "../../services/tgstat/channel.service";
import {getTreeNodes} from "../../util/getTreeNodes";
import {getFlattenObject} from "../../util/getFlattenObject";

class Channel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'isEmpty', // isLoading, isEmpty, isContent, isNotFound, isError
            content: {},
            settingsOpened: Number(localStorage.getItem('Channel.settingsOpened') || 0),
            settingsGetInfo: Number(localStorage.getItem('Channel.settingsGetInfo') || 1),
            settingsGetStat: Number(localStorage.getItem('Channel.settingsGetStat') || 1),
            // status: 'isContent',
            // content: { "id": 5859579, "tg_id": 1388604974, "link": "t.me/beautyrzdt", "peer_type": "channel", "username": "@beautyrzdt", "active_usernames": [ "@beautyrzdt" ], "title": "Бьютизавхоз", "about": "Меня читают красотки-нереалки!\n\nЗнаю на какие банки надо щедро тратиться, а какие #говнаПирога.\n\nсотрудничество (без ссылок не отвечаю) - @prbeautyzavhoz", "category": "Мода и красота", "country": "Россия", "language": "Русский", "image100": "//static4.tgstat.ru/channels/_100/7c/7cbf5c982c0ea1073c9c6449a09b2431.jpg", "image640": "//static8.tgstat.ru/channels/_0/7c/7cbf5c982c0ea1073c9c6449a09b2431.jpg", "participants_count": 5256, "tgstat_restrictions": [], "avg_post_reach": 1899, "adv_post_reach_12h": 712, "adv_post_reach_24h": 938, "adv_post_reach_48h": 1169, "err_percent": 36.1, "err24_percent": 17.8, "er_percent": 3.61, "daily_reach": 8016, "ci_index": 39.5973, "mentions_count": 566, "forwards_count": 24, "mentioning_channels_count": 122, "posts_count": 2176 }
        }
    }

    handleError = (error) => {
        if (String(error) === 'Error: channel_not_found') {
            this.setState({status: 'isNotFound'});
        } else {
            this.setState({status: 'isError'});
        }
        console.log(error);
    }

    handleSearch = async (value) => {
        this.setState({status: 'isLoading', content: []})
        try {
            let channelInfo = {}
            let channelStat = {}
            if (this.state.settingsGetInfo) channelInfo = await channelService.getInfo(value)
            if (this.state.settingsGetStat) channelStat = await channelService.getStat(value)
            this.setState({ content: { ...channelInfo, ...channelStat } });
            this.setState({status: 'isContent'});
            console.log({ ...channelInfo, ...channelStat })
        } catch (error) {
            this.handleError(error);
        }
    }

    getValueForUser = (key, value) => {
        switch (key) {
            case 'date': return new Date(value * 1000).toLocaleString() || '';
            case 'peer_type': {
                if (value === 'channel') return this.props.t('Channel.channel.channel')
                if (value === 'chat') return this.props.t('Channel.channel.chat')
                return ;
            }
            default: return value;
        }
    }

    handleSettingsOpenedChange = (value) => {
        localStorage.setItem('Channel.settingsOpened', Number(value))
        this.setState({settingsOpened: Number(value)});
    };

    handleSettingGetStatChange = (value) => {
        localStorage.setItem('Channel.settingsGetStat', Number(value))
        this.setState({settingsGetStat: Number(value)});
    }
    handleSettingGetInfoChange = (value) => {
        localStorage.setItem('Channel.settingsGetInfo', Number(value))
        this.setState({settingsGetInfo: Number(value)});
    }

    render() {
        return(
            <>
                <Space.Compact style={{width: '100%'}}>
                    <Popover
                        content={<>
                            <Switch size="small"
                                    checked={this.state.settingsGetInfo}
                                    onChange={this.handleSettingGetInfoChange} />
                            <span> {this.props.t('Channel.settings.getInfo')}</span>
                            <br />
                            <Switch size="small"
                                    checked={this.state.settingsGetStat}
                                    onChange={this.handleSettingGetStatChange} />
                            <span> {this.props.t('Channel.settings.getStat')}</span>
                        </>}
                        placement="bottomRight"
                        title={
                            <Tooltip placement="top" title={this.props.t('Channel.settings.titleHelp')}>
                                <Badge count={<QuestionCircleOutlined/>} style={{ color: '#9261f1', paddingLeft: 20 }}>
                                    {this.props.t('Channel.settings.title')}
                                </Badge>
                            </Tooltip>
                        }
                        trigger="click"
                        open={this.state.settingsOpened}
                        onOpenChange={this.handleSettingsOpenedChange}
                    >
                        <Button size="large" icon={<SettingOutlined />} />
                    </Popover>
                    <Input.Search placeholder={this.props.t(`Channel.search.placeholder`)}
                                  enterButton={this.props.t(`Channel.search.button`)}
                                  size="large"
                                  onSearch={this.handleSearch}
                                  loading={ this.state.status === 'isLoading' } />
                </Space.Compact>
                { this.state.status === 'isEmpty' && (
                    <Result style={{paddingTop: '25vh'}}
                            icon={<Empty description={false} />}
                            status="warning"
                            title={this.props.t(`Channel.search.empty`)}
                    />
                )}
                { this.state.status === 'isContent' && (
                    <Row style={{width: '100%', position: 'relative', marginTop: '40px'}}>
                        <Col span={16}>
                            <Table
                                style={{width: '98%'}}
                                tableLayout="fixed"
                                columns={[
                                    { title: this.props.t(`Channel.table.property`), dataIndex: 'property', fixed: true, width: 200 },
                                    { title: this.props.t(`Channel.table.value`), dataIndex: 'value' },
                                ]}
                                dataSource={
                                    Object.entries(getFlattenObject(this.state.content))
                                        .filter(([k, v]) => typeof v !== 'object')
                                        .map(([key, value], idx) => ({
                                            key: idx,
                                            property: this.props.t(`Channel.channel.${key}`),
                                            value: this.getValueForUser(key, value),
                                        }))
                                }
                                pagination={false}
                                bordered
                            />
                            {/*{JSON.stringify(this.getFlattenObject(this.state.content), null, 4)}*/}
                        </Col>
                        <Col span={8}>
                            { this.state.content.image640 && (
                                <img
                                    src={`https://${this.state.content.image640}`} alt=""
                                    style={{width: '100%', borderRadius: 8, border: '1px solid #f0f0f0'}}
                                />
                            )}
                            <Tree
                                style={{marginTop: 20, overflowX: 'hidden'}}
                                showLine={{showLeafIcon: true}}
                                showIcon={true}
                                defaultExpandedKeys={['0-0-0']}
                                treeData={getTreeNodes(this.state.content)}
                            />
                        </Col>
                    </Row>
                )}
                { this.state.status === 'isNotFound' && (
                    <Result style={{marginTop: '80px', marginBottom: '80px'}}
                            icon={<CloseCircleOutlined />}
                            status="404"
                            title="404"
                            subTitle={this.props.t(`Channel.NotFound.subTitle`)}
                    />
                )}
                { this.state.status === 'isError' && (
                    <Result style={{marginTop: '80px', marginBottom: '80px'}}
                            status="warning"
                            title={this.props.t(`Channel.requestError`)}
                    />
                )}
                { this.state.status === 'isLoading' && (
                    <Spin
                        tip="Loading"
                        size="large"
                        style={{marginTop: -40, marginBottom: 0}}
                    >
                        <div style={{
                            padding: 50,
                            marginTop: 80,
                            marginBottom: 80,
                            background: 'rgba(0, 0, 0, 0.05)',
                            borderRadius: 4,
                        }} />;
                    </Spin>
                )}
            </>
        );
    }
}

export default withTranslation()(Channel)

/**
 * Making nodes tree from nested arrays/objects
 * @param {array / object} node Root node
 * @returns {array} Nodes tree e.g. [{}, {}, {}]
 */
const getTreeNodes = (node) => {
    const recursion = (node, level = [0], hasParent = true) => {
        // if type of current node is Array
        if (Array.isArray(node)) return node
            .map((value, idx) => hasParent
                ? recursion(value, [...level, idx], false)
                : {
                    title: '',
                    key: `${[...level, idx].join('-')}`,
                    // keep working for children
                    children: recursion(value, [...level, idx ])
                })
        // if type of current node is Object
        if (typeof node === 'object' && node !== null) return Object.entries(node)
            // return array of objects by iterating elements
            .map(([title, value], idx) => ({
                title,
                key: `${[...level, idx].join('-')}`,
                // keep working for children
                children: recursion(value, [...level, idx ], false)
            }))
        // if current node is Leaf (end of recursion)
        return [{ // return 'object in array' Leaf (it's endpoint)
            title: node,
            key: `${[...level, 0].join('-')}`,
        }]
    }
    return recursion(node)
}

export { getTreeNodes }

import axios from "axios";
import authService from "../auth.service";

// const API_URL = "http://localhost:8010/api/post/";
const API_URL = "/api/post/";

const getInfo = (post) => {
    return axios.post(API_URL + "info/get", {
            token: authService.getCurrentToken(),
            post,
        }).then(response => response.data)
        .then(data => {
            if (Object.hasOwn(data, 'content')) return data.content;
            throw new Error(data.message ?? 'Unknown error');
        })
        .then((content) => {
            if (Object.hasOwn(content, 'response')) return content.response;
            throw new Error(content.error ?? 'Unknown error');
        })
};

const getStat = (post) => {
    return axios.post(API_URL + "stat/get", {
            token: authService.getCurrentToken(),
            post,
        }).then(response => response.data)
        .then(data => {
            if (Object.hasOwn(data, 'content')) return data.content;
            throw new Error(data.message ?? 'Unknown error');
        })
        .then((content) => {
            if (Object.hasOwn(content, 'response')) return content.response;
            throw new Error(content.error ?? 'Unknown error');
        })
};

const TgstatPostService = {
    getInfo,
    getStat,
}


export default TgstatPostService;

import axios from "axios";
import authService from "../auth.service";

// const API_URL = "http://localhost:8010/api/balance/";
const API_URL = "/api/balance/";

const getBalance = () => {
    return axios.post(API_URL + "get", {
            token: authService.getCurrentToken(),
        })
        .then(response => response.data)
        .then(data => data.content)
        .then(content => content.response)
        .then(response => {
            if (Array.isArray(response)) {
                return response.map((t) => ({
                        title: t.title ?? null,
                        serviceKey: t.serviceKey ?? null,
                        expiredAt: t.expiredAt ?? null,
                        spentChannels: t.spentChannels?.split('/') ?? null,
                        spentRequests: t.spentRequests?.split('/') ?? null,
                        spentWords: t.spentWords?.split('/') ?? null,

                }))
            }
            return [];
        });
};

const TgstatBalanceService = {
    getBalance,
}


export default TgstatBalanceService;

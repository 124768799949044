import React from "react";
import { Button, Spin, Form, Input, Typography, Result } from "antd";
import { LockOutlined } from "@ant-design/icons";
import AuthService from "../services/auth.service";
import withAntdToken from "./wrappers/withAntdToken";
import withRouter from "./wrappers/withRouter";
import { withTranslation } from "react-i18next";

const { Text, Title } = Typography;

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'isBasic', // isLoading, isBasic, isError
            message: '',
        }
    }
    handleError = (message) => {
        this.setState({status: 'isError', message});
    }
    handleSubmit = ({password}) => {
        this.setState({status: 'isLoading'})
        AuthService.authenticate(password).then(() => {
            this.props.navigate("/board/balance");
            window.location.reload();
        }).catch((error) => {
            this.handleError(error.message || error.toString() || String(error));
        })
    }
    handleOk = () => {
        this.setState({status: 'isBasic'})
    }
    render() {
        return (
            <section style={{
                alignItems: "center",
                display: "flex",
                height: "100vh"
            }}>
                <div style={{
                    margin: "0 auto",
                    width: "380px"
                }}>
                    { this.state.status === 'isError' && (
                        <Result
                            status="error"
                            title={this.props.t('Login.error.default')}
                            subTitle={this.state.message}
                            extra={
                                <Button type="primary" key="console" onClick={this.handleOk}>
                                    {this.props.t('button.back')}
                                </Button>
                            }
                        />
                    )}
                    { (this.state.status === 'isLoading' || this.state.status === 'isBasic') && (
                        <Spin spinning={this.state.status === 'isLoading'}>
                            <div>
                                <Title style={{
                                    fontSize: this.props.token.fontSizeHeading2,
                                    display: 'inline'
                                }}>Ad<p style={{
                                    fontSize: this.props.token.fontSizeHeading2,
                                    color: '#ba80e6',
                                    display: 'inline'
                                }}>See</p></Title>
                                <Text style={{
                                    color: this.props.token.colorTextSecondary,
                                    display: 'block'
                                }}>
                                    { this.props.t('Login.welcome') }
                                </Text>
                            </div>
                            <Form
                                name="normal_login"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={this.handleSubmit}
                                layout="vertical"
                                requiredMark="optional"
                            >
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: this.props.t('Login.error.emptyPassword'),
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        type="password"
                                        placeholder={ this.props.t('placeholder.password') }
                                    />
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "0px" }}>
                                    <Button block="true" type="primary" htmlType="submit">
                                        { this.props.t('button.enter') }
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    )}
                </div>
            </section>
        );
    }
}

export default withRouter(withAntdToken(withTranslation()(Login)))
    // withRouter(

    // )

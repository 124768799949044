import React from "react";
import {Routes, Route} from "react-router-dom";
import { ConfigProvider } from 'antd';
import AuthService from "./services/auth.service";
import './i18n';
import "./App.css";

import Login from "./components/Login";
import Board from "./components/Board";
import NotFound from "./components/NotFound";
import Post from "./components/board/Post";
import Export from "./components/board/Export";
import Channel from "./components/board/Channel";
import Balance from "./components/board/Balance";
import withAntdToken from "./components/wrappers/withAntdToken";
import withRouter from "./components/wrappers/withRouter";

// import AuthVerify from "./common/AuthVerify";
// import EventBus from "./common/EventBus";

class App extends React.Component {

    authVerify = async () => {
        try {
            await AuthService.authenticate();
            if (this.props.location.pathname === '/login') { // TODO: import AuthVerify from "./common/AuthVerify";
                // TODO: setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
                // TODO: setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
                this.props.navigate("/board/balance");
                window.location.reload();
            }
        } catch {
            if (this.props.location.pathname !== '/login') {
                this.props.navigate("/login");
                window.location.reload();
            }
        }
    }

    async componentDidMount() {
        await this.authVerify();
        // EventBus.on("logout", () => { // TODO: EventBus
        //   logOut();
        // });
        //
        // return () => {
        //   EventBus.remove("logout");
        // };
    }

    render () {
        return (
            <ConfigProvider theme={{
                token: {
                    // ...this.props.token,
                    colorPrimary: '#9261f1',
                },
            }}>
            <>
                <Routes>
                    <Route exact path={"/login"} element={<Login/>} />
                    <Route path="board" element={<Board/>}>
                        <Route path="post" element={<Post/>} />
                        <Route path="export" element={<Export/>} />
                        <Route path="channel" element={<Channel/>} />
                        <Route path="balance" element={<Balance/>} />
                        <Route path="*" element={<NotFound/>} />
                    </Route>
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </>
            </ConfigProvider>
        );
    }
}

export default withRouter(withAntdToken(App));

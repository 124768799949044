import axios from "axios";
import MD5 from "crypto-js/md5";

// const API_URL = "http://localhost:8010/api/";
const API_URL = "/api/";

const authenticate = (password) => {
    const token = password
        ? MD5(password).toString()
        : localStorage.getItem("token") || '';
    return axios
        .post(API_URL + "login", { // bb9480cc06235e12fe446edc4140683f
            token,
        })
        .then((response) => {
            localStorage.setItem("token", token);
            return response.data;
        })
        .catch((error) => {
            throw new Error((error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString());
        });
};

const getCurrentToken = () => {
    return localStorage.getItem("token");
};

const AuthService = {
    authenticate,
    getCurrentToken,
}

export default AuthService;

import React from "react";
import {Empty, Spin, Input, Result, Tree, Row, Col, Table, Space, Button, Popover, Switch, Tooltip, Badge} from "antd";
import {CloseCircleOutlined, QuestionCircleOutlined, SettingOutlined} from '@ant-design/icons';
import postService from "../../services/tgstat/post.service";
import Publication from "../Publication";
import {withTranslation} from "react-i18next";
import {getFlattenObject} from "../../util/getFlattenObject";
import {getTreeNodes} from "../../util/getTreeNodes";

class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'isEmpty', // isLoading, isEmpty, isContent, isNotFound, isError
            content: {},
            settingsOpened: Number(localStorage.getItem('Post.settingsOpened') || 0),
            settingsGetInfo: Number(localStorage.getItem('Post.settingsGetInfo') || 1),
            settingsGetStat: Number(localStorage.getItem('Post.settingsGetStat') || 1),
            // status: 'isContent',
            // content: { "id": 54285767660, "date": 1717585198, "views": [ { "date": "2024-06-05", "viewsGrowth": 19072 }, { "date": "2024-06-06", "viewsGrowth": 11047 }, { "date": "2024-06-07", "viewsGrowth": 2369 }, { "date": "2024-06-08", "viewsGrowth": 309 } ], "link": "t.me/kraschp/22130", "channel_id": 929998, "forwarded_from": null, "is_deleted": 0, "deleted_at": null, "group_id": null, "text": "🌿 <b>Ищете поставщика растений? В питомнике Венев огромный ассортимент и скидки на туи!</b>\n\nСкачать оптовый каталог и оформить заявку можно на <a href='https://thuja.venev1.ru/?utm_source=tg&utm_medium=post&utm_campaign=kraschp05&utm_content=teamOK&utm_term=2024-venev&utm_erid=LjN8K45Lr' rel='nofollow'>сайте</a><a href='https://sale100.venev1.ru/?utm_source=tg&utm_medium=post&utm_campaign=svobodakassa05&utm_content=teamOK&utm_term=2024-venev&utm_erid=LjN8JuEjR' rel='nofollow'>.</a> В наличии туи, можжевельники, ели, гортензии, сосны, сирени, пузыреплодники и еще более 5000 позиций. \n\n<b>Венев обеспечит:</b>\n✔️ Растения от производителя без посредников и переплат\n✔️ Онлайн-подбор продукции\n✔️ Доставку по РФ и СНГ собственной транспортной компанией", "media": { "media_type": "mediaPhoto", "caption": "", "file_size": 184550, "file_url": "https://static33.tgcnt.ru/posts/_0/ff/ffce1f0422f3c9d75528fcbb45f6a00b.jpg", "file_thumbnail_url": "https://static34.tgcnt.ru/posts/_1080/ff/ffce1f0422f3c9d75528fcbb45f6a00b.jpg" }, "viewsCount": 32797, "sharesCount": 12, "commentsCount": 3456, "reactionsCount": 44, "forwardsCount": 0, "mentionsCount": 0, "forwards": [], "mentions": [] }
        }
    }

    handleError = (error) => {
        if (String(error) === 'Error: post_not_found') {
            this.setState({status: 'isNotFound'});
        } else {
            this.setState({status: 'isError'});
        }
        console.log(error);
    }

    handleSearch = async (value) => {
        this.setState({status: 'isLoading', content: []})
        try {
            let postInfo = {}
            let postStat = {}
            if (this.state.settingsGetInfo) postInfo = await postService.getInfo(value)
            if (this.state.settingsGetStat) postStat = await postService.getStat(value)
            this.setState({ content: {...postInfo, ...postStat} });
            this.setState({ status: 'isContent' });
            console.log({ ...postInfo, ...postInfo })
        } catch (error) {
            this.handleError(error);
        }
    }

    getValueForUser = (key, value) => {
        switch (key) {
            case 'text': return `${value.slice(0, 40)}...`;
            case 'date': return new Date(value * 1000).toLocaleString() || '';
            case 'is_deleted': return value ? this.props.t(`Post.yes`) : this.props.t(`Post.no`);
            case 'media.media_type':
                if ([
                    'mediaDocument',
                    'mediaWebPage',
                    'mediaPhoto',
                    'mediaGeo',
                    'mediaContact',
                    'mediaVenue',
                    'mediaGeoLive',
                    'mediaGame',
                    'mediaInvoice',
                ].includes(value)) return this.props.t(`Post.publication.mediaType.${value}`)
                return this.props.t(`Post.publication.mediaType.unknown`)
            default: return value;
        }
    }

    handleSettingsOpenedChange = (value) => {
        localStorage.setItem('Post.settingsOpened', Number(value))
        this.setState({settingsOpened: Number(value)});
    };

    handleSettingGetStatChange = (value) => {
        localStorage.setItem('Post.settingsGetStat', Number(value))
        this.setState({settingsGetStat: Number(value)});
    }
    handleSettingGetInfoChange = (value) => {
        localStorage.setItem('Post.settingsGetInfo', Number(value))
        this.setState({settingsGetInfo: Number(value)});
    }

    render() {
        return(
            <>
                <Space.Compact style={{width: '100%'}}>
                    <Popover
                        content={<>
                            <Switch size="small"
                                    checked={this.state.settingsGetInfo}
                                    onChange={this.handleSettingGetInfoChange} />
                            <span> {this.props.t('Post.settings.getInfo')}</span>
                            <br />
                            <Switch size="small"
                                    checked={this.state.settingsGetStat}
                                    onChange={this.handleSettingGetStatChange} />
                            <span> {this.props.t('Post.settings.getStat')}</span>
                        </>}
                        placement="bottomRight"
                        title={
                            <Tooltip placement="top" title={this.props.t('Post.settings.titleHelp')}>
                                <Badge count={<QuestionCircleOutlined/>} style={{ color: '#9261f1', paddingLeft: 20 }}>
                                    {this.props.t('Post.settings.title')}
                                </Badge>
                            </Tooltip>
                        }
                        trigger="click"
                        open={this.state.settingsOpened}
                        onOpenChange={this.handleSettingsOpenedChange}
                    >
                        <Button size="large" icon={<SettingOutlined />} />
                    </Popover>
                    <Input.Search placeholder={this.props.t(`Post.search.placeholder`)}
                                  enterButton={this.props.t(`Post.search.button`)}
                                  size="large"
                                  onSearch={this.handleSearch}
                                  loading={ this.state.status === 'isLoading' } />
                </Space.Compact>
                { this.state.status === 'isEmpty' && (
                    <Result style={{paddingTop: '25vh'}}
                        icon={<Empty description={false} />}
                        status="warning"
                        title={this.props.t(`Post.search.empty`)}
                    />
                )}
                { this.state.status === 'isContent' && (
                    <Row style={{width: '100%', position: 'relative', marginTop: '40px'}}>
                        <Col span={16}>
                            <Table
                                style={{width: '98%'}}
                                tableLayout="fixed"
                                columns={[
                                    { title: this.props.t(`Post.table.property`), dataIndex: 'property', fixed: true, width: 100 },
                                    { title: this.props.t(`Post.table.value`), dataIndex: 'value' },
                                ]}
                                dataSource={
                                    Object.entries(getFlattenObject(this.state.content))
                                        .filter(([k, v]) => typeof v !== 'object')
                                        .map(([key, value], idx) => ({
                                            key: idx,
                                            property: this.props.t(`Post.publication.${key}`),
                                            value: this.getValueForUser(key, value),
                                        }))
                                }
                                pagination={false}
                                bordered
                            />

                        </Col>
                        <Col span={8}>
                            <Publication data={this.state.content}/>
                            <Tree
                                style={{marginTop: 20, overflowX: 'hidden'}}
                                showLine={{showLeafIcon: true}}
                                showIcon={true}
                                defaultExpandedKeys={['0-0-0']}
                                treeData={getTreeNodes(this.state.content)}
                            />
                        </Col>
                    </Row>
                )}
                { this.state.status === 'isNotFound' && (
                    <Result style={{marginTop: '80px', marginBottom: '80px'}}
                        icon={<CloseCircleOutlined />}
                        status="404"
                        title="404"
                        subTitle={this.props.t(`Post.NotFound.subTitle`)}
                    />
                )}
                { this.state.status === 'isError' && (
                    <Result style={{marginTop: '80px', marginBottom: '80px'}}
                        status="warning"
                        title={this.props.t(`Post.requestError`)}
                    />
                )}
                { this.state.status === 'isLoading' && (
                    <Spin
                        tip="Loading"
                        size="large"
                        style={{marginTop: -40, marginBottom: 0}}
                    >
                        <div style={{
                            padding: 50,
                            marginTop: 80,
                            marginBottom: 80,
                            background: 'rgba(0, 0, 0, 0.05)',
                            borderRadius: 4,
                        }} />;
                    </Spin>
                )}
            </>
        );
    }
}

export default withTranslation()(Post)

/**
 * Making nested object structure flat (like Array.flat() for Object). Was { obj2: { obj3: {} } } - will
 * be [...obj1, ...obj2, ...obj3] but now obj1 not includes obj2, obj2 not includes obj3.
 * @param {object} obj Root object
 * @returns {object} Flatten object
 */
const getFlattenObject = (obj) => {
    const recursion = (obj, roots = [], sep = '.') => Object
        // find props of given object
        .keys(obj)
        // return an object by iterating props
        .reduce((memo, prop) => Object.assign(
            // create a new object
            {},
            // include previously returned object
            memo,
            Object.prototype.toString.call(obj[prop]) === '[object Object]'
                // keep working if value is an object
                ? recursion(obj[prop], roots.concat([prop]), sep)
                // include current prop and value and prefix prop with the roots
                : {[roots.concat([prop]).join(sep)]: obj[prop]}
        ), {})
    return recursion(obj)
}

export { getFlattenObject }

import axios from "axios";
import authService from "../auth.service";

// const API_URL = "http://localhost:8010/api/channel/";
const API_URL = "/api/channel/";

const getInfo = (channel) => {
    return axios.post(API_URL + "info/get", {
        token: authService.getCurrentToken(),
        channel,
    }).then(response => response.data)
        .then(data => {
            if (Object.hasOwn(data, 'content')) return data.content;
            throw new Error(data.message ?? 'Unknown error');
        })
        .then((content) => {
            if (Object.hasOwn(content, 'response')) return content.response;
            throw new Error(content.error ?? 'Unknown error');
        })
};

const getStat = (channel) => {
    return axios.post(API_URL + "stat/get", {
        token: authService.getCurrentToken(),
        channel,
    }).then(response => response.data)
        .then(data => {
            if (Object.hasOwn(data, 'content')) return data.content;
            throw new Error(data.message ?? 'Unknown error');
        })
        .then((content) => {
            if (Object.hasOwn(content, 'response')) return content.response;
            throw new Error(content.error ?? 'Unknown error');
        })
};

const TgstatChannelService = {
    getInfo,
    getStat,
}


export default TgstatChannelService;

import React from "react";
import "../publicationStyle.css"
import {withTranslation} from "react-i18next";

class Publication extends React.Component {
    // constructor(props) {
    //     super(props);
    //     const {
    //         date,
    //         text,
    //         viewsCount,
    //         sharesCount,
    //         commentsCount,
    //         reactionsCount,
    //         forwardsCount,
    //     } = this.props.data
    // }
    render() {
        return (
            <div className="publication">
                <div className="publication__title">
                    { this.props.data.title }
                </div>
                { !!this.props.data.media
                    && !!this.props.data.media.media_type
                    && this.props.data.media.media_type === 'mediaPhoto'
                    && (
                        <div className="image-block image-block--first-row-one">
                            <div>
                                <span><img alt="" src={ this.props.data.media.file_url}/></span>
                            </div>
                        </div>
                    )
                }
                { !!this.props.data.title && (
                    <div className="publication__title">
                        { this.props.data.title }
                    </div>
                )}
                { !!this.props.data.text && (
                    <div
                        className="text-block"
                        dangerouslySetInnerHTML={{__html: this.props.data.text}}>
                    </div>
                )}
                { this.props.data.reactionsCount > 0 && (
                    <div className="emoji-block">
                        <div className="emoji">
                            {/*<span>*/}
                            {/*    { this.props.t('Publication.reactionsCount', { count: this.props.data.reactionsCount }) }*/}
                            {/*</span>*/}
                            {/*{ this.props.data.reactionsCount }*/}
                            { this.props.t('Publication.reactionsCount', { count: this.props.data.reactionsCount }) }
                        </div>
                    </div>
                )}
                <div className="statistics-block">
                    { this.props.data.viewsCount > 0 && (
                        <span className="views"><i className="icon">
                            <img alt="" src="/img/publication/icon-eye.svg"/></i> { this.props.data.viewsCount }
                        </span>
                    )}
                    { this.props.data.date && (
                        <span>{ new Date(this.props.data.date * 1000).toLocaleString() }</span>
                    )}
                </div>
                { this.props.data.commentsCount > 0 && (
                    <div className="footer-with-comments-block">
                        <div className="avatars">
                            <div className="avatar"
                                 style={{backgroundImage: 'url(/img/publication/pic/11.webp)'}}>
                            </div>
                            <div className="avatar"
                                 style={{backgroundImage: 'url(/img/publication/pic/11.webp)'}}>
                            </div>
                            <div className="avatar"
                                 style={{backgroundImage: 'url(/img/publication/pic/11.webp)'}}>
                            </div>
                        </div>
                        <div className="footer-with-comments-block__text">
                            { this.props.t('Publication.commentsCount', { count: this.props.data.commentsCount }) }
                        </div>
                        <div className="footer-with-comments-block__arrow">
                            <img alt="" src="/img/publication/right.svg"/>
                        </div>
                    </div>
                )}
            </div>
        )
    }
};

export default withTranslation()(Publication);

import React from "react";
import TgstatBalanceService from "../../services/tgstat/balance.service";
import {Card, Empty, Progress, Result, Space, Spin, Tooltip} from "antd";
import {withTranslation} from "react-i18next";



class Balance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 'isEmpty', // isLoading, isContent, isError
            tariffs: []
        };
    }

    componentDidMount = async () => {
        this.setState({status: 'isLoading', tariffs: []})
        try {
            const tariffs = await TgstatBalanceService.getBalance();
            this.setState({ status: 'isContent', tariffs })
        } catch {
            this.setState({ status: 'isError' })
        }
    }

    render() {
        return (
            <>
                <h2>{this.props.t('Balance.title')}</h2>
                <Spin spinning={ this.state.status === 'isLoading'}>
                    <Space direction="horizontal" size="large" align="start">
                        { this.state.status === 'isContent' && (
                            this.state.tariffs.map((tariff, index) => {
                                return (
                                    <Card title={tariff.title ?? this.props.t(Balance.tariff.nameless)} size="small" key={index}>
                                        <div style={{ height: '20px' }} ></div>
                                        {Array.isArray(tariff.spentChannels) && (
                                            <Space.Compact direction="vertical" align="center">
                                                <Tooltip
                                                    style={{display: "block"}}
                                                    title={`
                                                        ${tariff.spentChannels.at(-1) - tariff.spentChannels.at(0)} из ${tariff.spentChannels.at(-1)}
                                                    `}>
                                                    <Progress
                                                        strokeColor="#9261f1"
                                                        style={{marginLeft: '6px', marginRight: '6px', fontSize: '1px'}}
                                                        type="dashboard"
                                                        percent={100 - tariff.spentChannels.at(0) * 100 / tariff.spentChannels.at(-1)}
                                                        format={() => tariff.spentChannels.at(-1) - tariff.spentChannels.at(0)}
                                                    />
                                                </Tooltip>
                                                <div style={{
                                                    marginRight: '6px',
                                                    marginTop: -10,
                                                    paddingBottom: 10,
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    {this.props.t('Balance.tariff.channels')}
                                                </div>
                                            </Space.Compact>
                                        )}
                                        {Array.isArray(tariff.spentRequests) &&
                                            <Space.Compact direction="vertical" align="center">
                                                <Tooltip
                                                    style={{display: "block"}}
                                                    title={`
                                                        ${tariff.spentRequests.at(-1) - tariff.spentRequests.at(0)} из ${tariff.spentRequests.at(-1)}
                                                    `}>
                                                    <Progress
                                                        strokeColor="#9261f1"
                                                        style={{marginLeft: '6px', marginRight: '6px', fontSize: '1px'}}
                                                        type="dashboard"
                                                        percent={100 - tariff.spentRequests.at(0) * 100 / tariff.spentRequests.at(-1)}
                                                        format={() => tariff.spentRequests.at(-1) - tariff.spentRequests.at(0)}
                                                    />
                                                </Tooltip>
                                                <div style={{
                                                    marginRight: '6px',
                                                    marginTop: -10,
                                                    paddingBottom: 10,
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    {this.props.t('Balance.tariff.requests')}
                                                </div>
                                            </Space.Compact>
                                        }
                                        {Array.isArray(tariff.spentWords) &&
                                            <Space.Compact direction="vertical" align="center">
                                                <Tooltip
                                                    style={{display: "block"}}
                                                    title={`
                                                       ${tariff.spentWords.at(-1) - tariff.spentWords.at(0)} из ${tariff.spentWords.at(-1)}
                                                    `}>
                                                    <Progress
                                                        strokeColor="#9261f1"
                                                        style={{marginLeft: '6px', marginRight: '6px', fontSize: '1px'}}
                                                        type="dashboard"
                                                        percent={100 - tariff.spentWords.at(0) * 100 / tariff.spentWords.at(-1)}
                                                        format={() => tariff.spentWords.at(-1) - tariff.spentWords.at(0)}
                                                    />
                                                </Tooltip>
                                                <div style={{
                                                    marginRight: '6px',
                                                    marginTop: -10,
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    {this.props.t('Balance.tariff.words')}
                                                </div>
                                            </Space.Compact>
                                        }
                                        {tariff.expiredAt &&
                                            <p style={{marginTop: 20, marginBottom: 0}}>
                                                {this.props.t('Balance.tariff.expiredAt')}: {
                                                    new Date(tariff.expiredAt * 1000).toLocaleString()
                                                }
                                            </p>
                                        }
                                    </Card>
                                )
                            })
                        )}
                        { this.state.status === 'isEmpty' && (
                            <Empty
                                description={this.props.t('Balance.empty')}
                                style={{marginTop: '80px', marginBottom: '80px'}}
                            />
                        )}
                        { this.state.status === 'isError' && (
                            <Result style={{marginTop: '80px', marginBottom: '80px'}}
                                    status="warning"
                                    title={this.props.t(`Balance.requestError`)}
                            />
                        )}
                    </Space>
                </Spin>
            </>
        );
    }

}

export default withTranslation()(Balance)

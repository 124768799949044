import {notification} from "antd";

function withNotification(Component) {
    function ComponentWithNotificationProps(props) {
        const [api, contextHolder] = notification.useNotification();

        return (
            <Component {...props} notification={api}  contextHolder={contextHolder} />
        );
    }

    return ComponentWithNotificationProps;
}

export default withNotification;

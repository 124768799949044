import React from "react";
import {Result} from "antd";
import {withTranslation} from "react-i18next";

class NotFound extends React.Component {
    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle={this.props.t('NotFound.subTitle')}
            />
        )
    }
};

export default withTranslation()(NotFound);
